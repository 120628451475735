<template>
  <div>
    <div
      show
      variant="light"
      class="
        alert alert-custom alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-col>
        <b-container >
          <b-col ><label :for="`date`">Đối tượng</label></b-col>
          <b-col >
            <b-form-select
                v-model="object"
                :options="objectType"
            ></b-form-select>
          </b-col>
        </b-container>
      </b-col>
      <b-col>
        <b-container >
          <b-col ><label :for="`date`">Loại dịch vụ</label></b-col>
          <b-col >
            <b-form-select
                v-model="service"
                :options="filteredServiceType"
            ></b-form-select>
          </b-col>
        </b-container>
      </b-col>


      <b-col>
        <b-container >
          <b-col ><label :for="`date`">Từ ngày:</label></b-col>
          <b-col >
            <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="dateFrom"
            ></b-form-input>
          </b-col>
        </b-container>
      </b-col>

      <b-col>
        <b-container >
          <b-col ><label :for="`date`">Đến ngày:</label></b-col>
          <b-col>
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="dateTo"
            ></b-form-input>
          </b-col>
        </b-container>
      </b-col>

      <b-col>
        <b-col  class="pt-2"><label ></label></b-col>
        <b-col class="text-right">
          <b-button-group>
            <b-button type="button" variant="primary" @click="getList()">
              <i class="la la-search"></i>
              <span>Tìm kiếm</span>
            </b-button>

            <b-button type="button" variant="success" @click="$bvModal.show(modalId)">
              <i class="la la-file-export"></i>
              <span>Xuất file</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-col>
    </div>

<!--    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{-->
<!--        errorMessage-->
<!--      }}</b-card-text>-->

    <b-card>
      <b-card-body>
        <p v-if="!data">Không có dữ liệu</p>
        <div v-else class="table-responsive text-center">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>STT</th>
                <th>Time</th>
                <th>Đối tượng</th>
                <th>Loại dịch vụ</th>
                <th>Tổng SLGD</th>
                <th>Tổng giá trị GD</th>
                <th>Phí thu</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="key" v-for="(item, key) in data " :style="(item.provider || (item.provider == 'Tổng cộng')) ? '' : 'font-weight: 600'">
                <td>{{ item.service ? (key + 1) : '' }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.provider }}</td>
                <td>{{ item.service }}</td>
                <td>{{ numberFormat(item.count) }}</td>
                <td>{{ numberFormat(item.amount) }}</td>
                <td>{{ numberFormat(item.fee_received) }}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <b-pagination-nav
              v-model="currentPage"
              :link-gen="createLinkPage"
              :number-of-pages="totalPage"
              use-router
              first-number
              last-number
              align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import CmsRepository from "@/core/repositories/cmsRepository";
const FeeRepository = RepositoryFactory.get("fee");

export default {
  name: "Report",
  components: { ExportModal, AlertDialogue },
  mixins: [Common],
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      modalId: "export_report_fee",
      data: {},
      service: null,
      object: null,
      dateFrom: this.$route.query.date_from ?? this.getFirstDayOfMonth(),
      dateTo: this.$route.query.date_to ?? this.getCurrentDay(),
      errorMessage: "Vui lòng chọn thời gian",
      objectType: [
        { value: null, text: "Tất cả" },
        { value: "WITHDRAW", text: "Rút liên kết" },
        { value: "WITHDRAW_BANK_OTHER", text: "Rút tiền về bank" },
        { value: "CREDIT_CARD", text: "Thanh toán bằng thẻ Quốc tế" },
      ],
      serviceType: {
        all: [{ value: null, text: "Tất cả" }],
        WITHDRAW: [
          { value: null, text: "Tất cả" },
          { value: "DIRECT_LINK", text: "Rút bank liên kết trực tiếp" },
          { value: "NAPAS", text: "Rút bank liên kết NAPAS" },
        ],
        WITHDRAW_BANK_OTHER: [
          { value: null, text: "Tất cả" },
          { value: "COLLECT_FEE", text: "Thu phí" },
          { value: "FREE_FEE", text: "Miễn phí" },
        ],
        CREDIT_CARD: [{ value: null, text: "Tất cả" }],
      },
      option_service: [
        { value: null, text: "Tất cả" },
        { value: "NAPAS", text: "Rút qua NAPAS" },
        { value: "DIRECT_LINK", text: "Rút bank liên kết trực tiếp" },
        { value: "CREDIT_CARD", text: "Thanh toán bằng thẻ Quốc tế" },
        { value: "WITHDRAW_BANK_OTHER", text: "Rút qua chuyển khoản" },
      ],
    };
  },
  methods: {
    getList(numberPage = null) {
      if (this.dateFrom == null) {
        this.notifyAlert("warn", "Vui lòng chọn thời gian");
        return false;
      }
      this.$bus.$emit("show-loading", true);
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      }

      this.data = {};
      this.errorMessage = null;
      let params = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
        object: this.object,
        service: this.service,
        page: this.currentPage,
        fee_type: 'FEE_CUSTOMER',
      };
      params = this.removeValidateNull(params);
      return FeeRepository.feeDetail(params)
        .then((response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            return (this.errorMessage = "Có lỗi khi lấy thông tin");
          }
          this.data = response.data.data.data;
          this.totalPage = response.data.data.lastPage;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (this.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async exportData(description = null) {
      this.$bvModal.hide(this.modalId);
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      if (this.dateFrom == null) {
        this.notifyAlert("warn", "Vui lòng chọn thời gian");
        return false;
      }
      let params = {
        wallet_type: "FEE_CUSTOMER",
        date_from: this.dateFrom,
        date_to: this.dateTo,
        service: this.service,
        export_desc: description ?? "Báo cáo chi tiết phí thu khách hàng",
        fee_type: 'FEE_CUSTOMER'
      };

      params = this.removeValidateNull(params);
      return CmsRepository.exportFileReconcileWallet(params)
        .then(async (response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            self.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }
          let link = window.location.origin + "/#/reconcile/export-data";

          self.$bus.$emit("show-loading", false);
          const cfm = await this.$refs.confirmDialogue.show({
            title: "Download File",
            message:
              "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" +
              link +
              "' target='_blank'>TẠI ĐÂY</a>",
            okButton: "Ok",
          });

          if (!cfm) {
            this.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (self.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    filteredServiceType() {
      return this.serviceType[this.object] || this.serviceType.all;
    },

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    "currentPage"() {
      this.getList(this.currentPage);
    },
    object() {
      this.service = null;
    },
  },
  created() {

  },
  mounted() {
    if (this.dateFrom  != null && this.dateTo != null) {
      this.getList();
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo chi tiết phí thu khách hàng" },
    ]);
  },
};
</script>
